.tours__cta-entire-container {
    display: flex;
    width: 100%;
    /* background-color: #26153d; */
    background-color: #dfedec;
    /* background-color: white; */
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: center;
    font-family: var(--font-poppins);
}

.tours__cta {
    display: flex;
    /* flex: 1; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 3rem;
    margin-right: 10rem;
    margin-left: 10rem; */
    /* padding-left: clamp(15px, 1.5vw, 30px);
    padding-right: clamp(15px, 1.5vw, 30px);
    padding-top: 10px;
    padding-bottom: 10px; */
    padding: 30px clamp(15px, 1.5vw, 30px);
    border-radius: 30px;
    width: clamp(300px, 80vw, 3000px);
    /* width: 100%; */
    background: var(--gradient-bar);
}

.tours__cta-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #fff;
}

.tours__cta-content p {
    /* font-family: var(--font-manrope); */
    /* font-size: 12px; */
    font-size: clamp(12px, 2vw, 18px);
    line-height: 30px;
    font-weight: 500;
}
.tours__cta-content h3 {
    /* font-family: var(--font-manrope); */
    font-size: clamp(15px, 2.5vw, 24px);
    /* line-height: 45px; */
    font-weight: 800;
}

.tours__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.newtoppart__joinus-button {
    background: #000;
    color: #fff;
    /* font-family: var(--font-manrope); */
    font-family: var(--font-poppins);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 80px;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 1023px) {
    .tours__cta {
        flex-direction: column;
        gap: clamp(15px, 4vw, 20px);
    }

    .tours__cta-content {
        align-items: center;
        text-align: center;
    }
}



/* @media screen and (max-width: 650px) {
    .tours__cta {
        flex-direction: column;
    }

    .tours__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .tours__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .tours__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .tours__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
} */