.guides__entire-container {
    margin-top: 3rem;
    margin-right: 10rem;
    margin-left: 10rem;
    margin-bottom: 2rem;
}

.guides__text {
    text-align: center;
}

.guides__small-text {
    color: var(--color-orange);
    font-family: var(--font-manrope);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.03125rem;
}

.guides__large-text {
    color: black;
    font-family: var(--font-manrope);
    font-size: 2rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: .01875rem;
}

.guides__card-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    /* gap: 8vh; */
    /* gap */
    /* gap: 6vh; */
    gap: 2rem;
}

.guides__card-top-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    /* gap: 1vw; */
    /* gap: 3%; */
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
    /* row-gap: 3vh; */
}

.guides__card-bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3%;
}

