.coming__entire-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin: 6rem 10rem 6rem 10rem; */
    padding: 2vh 2vw;
    height: 100vh;
    flex: 1;
}

.coming__left-side-container {
    display: flex;
    flex-direction: column;
    flex: .5;
    /* width: 30rem; */
    /* height: 50rem; */
    justify-content: space-between;
}

.coming__text-container {
    display: flex;
    flex-direction: column;
}

.coming__thankyou-message {
    color: black;
    font-family: var(--font-manrope);
    font-size: 2.5rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-bottom: 1rem;
}

.coming__information-message {
    color: black;
    font-family: var(--font-manrope);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 5rem;
}

.coming__beready-message {
    color: var(--color-orange);
    font-family: var(--font-manrope);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
}

.coming__email-container {
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem 0;
}

.coming__email-prompt {
    color: black;
    font-family: var(--font-manrope);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    margin: 0 0 1rem 0;
}

.coming__email-textbox {
    width: 20rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    background: #ffffffc7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 3rem;
    color: var(--color-grey-text_in_textbox);
    font-family: var(--font-manrope);
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0 0 0 1rem;
    margin: 0 0 1rem 0;
}

.coming__email-button {
    display: flex;
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
    border-style: none;
}

@keyframes buttonClickAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.coming__email-button--clicked {
    animation: buttonClickAnimation 0.5s;
}

.coming__right-side-container {
    display: flex;
    flex: .5;
}

.coming__right-side-container img {
    height: 100%;
    width: 100%;
}