.initialsdnd__entire-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--color-orange);
    border-radius: 100%;
    width: 40px;
    height: 40px;
}
.initialsdnd__entire-container p {
    font-family: var(--font-poppins);
    font-size: 1rem;
    font-weight: 700;
    color: black;
    margin: 0;
}