.steps__entire-container {
    margin: 3rem 10rem;
    display: flex;
    flex-direction: row;
    /* gap: 5rem; */
    gap: 5%;
}

.steps__left-side {
    display: flex;
    flex-direction: column;
    /* width: 40rem; */
    flex: 1;
    justify-content: space-between;
    gap: 3rem;
}

.steps__text-container {
    display: flex;
    flex-direction: column;
    width: 40rem;
}

.steps__text-container p {
    font-family: var(--font-manrope);
    color: var(--color-orange);
    font-weight: 700;
    font-size: 2.5rem;
}

.steps__text-container p1 {
    font-family: var(--font-manrope);
    color: black;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1rem;
}

.steps__testimonials-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.steps__4steps-container {
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-left: auto;
    flex: 1.2;
    justify-content: space-between;
}

.steps__top-row {
    display: flex;
    flex-direction: row;
    /* gap: 10rem; */
    justify-content: space-between;
}

.steps__bottom-row {
    display: flex;
    flex-direction: row;
    /* gap: 10rem; */
    justify-content: space-between;
}









.newsteps__entire-container {
    display: flex;
    padding: 2rem 10rem;
    align-items: center;
    justify-content: center;
}

.newsteps__4steps-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 5vw; */
    flex-wrap: wrap;
    width: 100%;
}
