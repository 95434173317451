* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-white);
}

.guideonboard__page-container {
    margin: 6rem 10rem 6rem 10rem;
}

.guideonboard__page-tracker-container {
    display: flex;
    flex-direction: column;
}

.guideonboard__page-tracker-progressbar {
    display: flex;
    flex-direction: row;
    /* horizontal distance between each box in progress bar */
    gap: .5rem;
    padding: 0 0 0 1rem;
}

.guideonboard__page-tracker-darkbar {
    width: 4rem;
    height: 1rem;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
}

.guideonboard__page-tracker-lightbar {
    width: 4rem;
    height: 1rem;
    flex-shrink: 0;
    border-radius: 15px;
    background: #FADE9A;
}

.guideonboard__page-tracker-greybar {
    width: 4rem;
    height: 1rem;
    flex-shrink: 0;
    border-radius: 15px;
    background: #D9D9D9;
}

.guideonboard__page-tracker-container p {
    color: black;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: 0.3px;
    padding: 0 0 0 1rem;
}

.guideonboard__all-interactive-elements-container {
    display: flex;
    flex-direction: column;
    height: 50rem;
    justify-content: space-between;
}

.guideonboard__all-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 0 0 1rem;
}

.guideonboard__page-4textboxes-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.guideonboard__page-4textboxes-toprow {
    display: flex;
    flex-direction: row;
    gap: 8rem;
}

.guideonboard__page-4textboxes-bottomrow {
    display: flex;
    flex-direction: row;
    gap: 8rem;
}

.guideonboard__text-and-input-container {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 1rem;
}

.guideonboard__text-message {
    width: 60rem;
}

.guideonboard__text-message p {
    color: #000;
    font-family: var(--font-manrope);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    text-align: right;
}

.guideonboard__photo-upload {
    display: flex;
    align-items: flex-start;
}

.guideononboard__image-upload-label {
    display: flex;
}

.guideononboard__image-upload-label button {
    height: 40rem;
    width: 30rem;
    font-family: var(--font-manrope);
    border-radius: 15px;
    background: var(--color-orange);
    border-width: 0px;
    cursor: pointer;
    color: black;
    font-size: 1.25rem;
    font-weight: 700;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    outline: none;
}

.guideonboard__photo-preview {
    display: flex;
    flex-direction: column;
    height: 40rem;
    width: 30rem;
    gap: 2rem;
    align-items: center;
    flex: 1;
}

.guideonboard__photo-preview img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 15px;
}

.guideonboard__rechoose-button {
    display: flex;
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
}

.guideonboard__page-button {
    display: flex;
    margin-top: 2rem;
    /* to get rid of weird grey box */
    padding: 0 0 1rem 1rem;
}

.guideonboard__page-button button {
    display: flex;
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
    border-style: none;
}

.guideonboard__page-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 0 1rem 1rem;
}

.backButton {
    display: flex;
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
    box-shadow: none;
}

.nextButton {
    display: flex;
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
    border-style: none;
}

.finishButton {
    display: flex;
    width: 10rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
    border-style: none;
}  