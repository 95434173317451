.mostnewsteps__entire-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1vw;
    padding: 2vh 3vw;
    /* border-bottom-left-radius: 40px; */
    /* border-bottom-right-radius: 40px; */
    background-color: #26153a;
    font-family: var(--font-poppins);
    height: clamp(8rem, 25vw, 25rem);
}

.mostnewsteps__right-side-container {
    display: flex;
    flex-direction: row;
    border: 2px solid white;
    /* background-color: #E6E6E6; */
    background-color: #26153a;
    border-radius: 15px;
    flex: 1;
    padding: 1vh 1vw;
    /* min-height: 30vh; */
}

.mostnewsteps__right-side-container h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-orange);
    flex: .12;
    font-size: clamp(9.5px, 1.9vw, 35px);
}

.mostnewsteps__joinus-button {
    border: none;
    background-color: var(--color-orange);
    color: white;
    width: 10vw;
    height: 5vh;
    border-radius: 15px;
    font-size: 1vw;
    font-family: var(--font-poppins);
    font-weight: bold;
    cursor: pointer;
}

.mostnewsteps__step-entire-container {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    padding-bottom: 1vh;
    flex: .22;
    /* flex: 1; */
}

.mostnewsteps__step-left-side-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    flex: .3;
    gap: 1vh;
}
.mostnewsteps__step-left-side-container img {
    /* width: 5vw; */
    width: 4vw;
    /* width: 80px; */
}

.mostnewsteps__step-left-line {
    height: 100%;
    border: 1px solid var(--color-orange);
}

.mostnewsteps__step-right-side-container {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; i don't think this is needed */
    flex: .7;
    /* width: 100%; */
    height: 100%;
    /* gap: 1rem; */
    justify-content: space-between;
    overflow: visible;
}
.mostnewsteps__step-right-side-container h2 {
    color: var(--color-orange);
    /* font-size: 1vw; */
    font-size: clamp(8px, 1.6vw, 35px);
}

.mostnewsteps__step-right-side-information-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mostnewsteps__border-text {
    /* width: 100%; */
    background-color: rgb(21, 206, 206);
    border-radius: 15px;
    padding-left: 5px;
    /* width: 10vw; */
}
.mostnewsteps__desktop-content {
    color: white;
    max-width: 20vw;
    /* font-size: .95vw; */
    font-size: clamp(3px, 1.02vw, 25px);
}

.mostnewsteps__mobile-content {
    display: none;
    font-size: clamp(8px, 1.02vw, 20px);
}

@media screen and (max-width: 430px) { /* for mobile phones */
    .mostnewsteps__entire-container {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .mostnewsteps__right-side-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .mostnewsteps__step-entire-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .mostnewsteps__step-left-side-container img {
        width: 10vw;
    }

    .mostnewsteps__step-left-line {
        display: none;
    }

    .mostnewsteps__right-side-container h1 {
        text-align: center;
        font-size: clamp(15px, 1.9vw, 35px);
        padding: 0px 8px 10px 8px;
    }

    .mostnewsteps__step-right-side-container h2 {
        font-size: clamp(14px, 1.6vw, 35px);
    }

    .mostnewsteps__step-right-side-container {
        justify-content: unset;
        height: 50px;
    }

    .mostnewsteps__mobile-content {
        display: flex;
        color: white;
        /* font-size: .95vw; */
        font-size: clamp(10px, 1.02vw, 25px);
    }

    .mostnewsteps__desktop-content {
        display: none;
    }
}