.reasons__entire-container {
    display: flex;
    flex-direction: column;
    margin: 2rem 10rem;
}

.reasons__main-text {
    text-align: center;
    font-family: var(--font-manrope);
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 2rem;
    color: #da18f0;
    background-image: -webkit-linear-gradient(45deg, #da18f0 0%, #9591e3 37%, #49eba7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}

.reasons__vertical-container {
    display: flex;
    flex-direction: column;
    /* padding-left: 12rem;
    padding-right: 12rem; */
}

.reasons__vertical-container p {
    font-family: var(--font-manrope);
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-orange);
}

.reasons__vertical-container img {
    /* width: 25rem; */
    width: 40%;
    height: auto;
}

.reasons__vertical-container x {
    font-family: var(--font-manrope);
}

.reasons__reason1-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.reasons__reason1-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* max-width: 35rem; */
    max-width: 50%;
}

.reasons__reason2-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.reasons__reason2-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* max-width: 35rem; */
    max-width: 50%;
}

.reasons__reason3-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.reasons__reason3-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* max-width: 35rem; */
    max-width: 50%;
}

.reasons__final-message {
    text-align: center;
    font-family: var(--font-manrope);
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 2rem;
    color: #da18f0;
    background-image: -webkit-linear-gradient(45deg, #da18f0 0%, #9591e3 37%, #49eba7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}