.boxcomponent__entire-container {
    --boxcomponent-size: 15vw;
  
    display: flex;
    flex-direction: column;
    /* background-color: white; */
    background-color: transparent;
    width: var(--boxcomponent-size);
    /* height: var(--boxcomponent-size); */
    min-width: 150px;
    min-height: 150px;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 100%;
    /* border-radius: 15px; */
    /* max-width: 18rem;
    max-height: 18rem; */
    /* justify-content: space-between; */
}
  
.boxcomponent__image-container {
    display: flex;
    flex-direction: row;
    width: 55%;
    /* height: 55%; */
    /* border-top-left-radius: 15px; */
    /* border-top-right-radius: 15px; */
}
  
.boxcomponent__image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.boxcomponent__text-container {
    display: flex;
    flex-direction: column;
    /* font-size: .9vw; */
    padding-left: 1vw;
    padding-right: 1vw;
    color: white;
}

.boxcomponent__university-text {
    font-size: clamp(12px, 1vw, 18px);
    color: var(--color-orange);
}

.boxcomponent__description-text,
.boxcomponent__class-text {
    font-size: clamp(12px, 1vw, 15px);
}