.header__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 10rem;
}

.header__text-big {
    color: black;
    font-family: var(--font-poppins);
    font-size: 4.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 5.625rem;
    letter-spacing: 0.3px;
    margin-bottom: 2rem;
}

.header__text-big text {
    color: var(--color-orange);
    font-family: var(--font-satisfy);
    font-size: 4.375rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.3px;
}

.header__text-small {
    color: #373737;
    font-family: var(--font-manrope);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: 0.5px;
}

.header__three-images-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: 35rem;
}

.header__two-vertical-images-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.header__one-vertical-image {
    max-width: 350px;
    max-height: 80%;
    border-radius: 15px;
    margin-right: 1rem;
}

.header__vertical-image1 {
    max-width: 250px;
    max-height: 50%;
    border-radius: 15px;
}

.header__vertical-image2 {
    max-width: 250px;
    max-height: 50%;
    border-radius: 15px;
}