.centered-box {
    --vertical-margin: 9vh;
    --horizontal-margin: 15vw;
    /* background-color: #F2F5F9; */
    background-color: white;
    /* border: 3px solid var(--color-orange); */
    border-radius: 15px;
    box-sizing: border-box;
    margin: var(--vertical-margin) var(--horizontal-margin); 
    width: calc(100vw - 2 * var(--horizontal-margin)); 
    height: calc(100vh - 2 * var(--vertical-margin)); 
}

.newsignup__entire-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-poppins);
    height: 100%;
}

.newsignup__right-side-container {
    display: flex;
    flex-direction: column;
    background: var(--color-orange);
    /* background-image: url('../../assets/signuppage_people.jpg'); */
    /* background-size: 80%; */
    background-repeat: no-repeat;
    flex: 0.8;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    padding: 10px 20px;
}

.newsignup__left-side-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* overflow: visible; */
    flex: 1;
    /* gap: 8vh; */
}

.newsignup__signin-redirect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-right: 2vw; */
    gap: 2vmin;
}

.newsignup__signup-messages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: clamp(9px, 0.75vw, 12px);
    color: red;
    text-align: center;
}

.newsignup__type-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 3vh; */
    gap: 2vmin;
    /* flex: 3.; */
}

.newsignup__type-images-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5vw;
}

.newsignup__imagetext-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: border 0.3s, text-decoration 0.3s;
    gap: 1vh;
}
.newsignup__imagetext-container img {
    /* flex: 1; */
    /* max-width: 15vmin; */
    /* width: 110px; */
    width: clamp(110px, 9vw, 150px);
    border: 2px dashed var(--color-orange);
    border-radius: 100%;
    cursor: pointer;
    /* max-height: 100%; */
}
.newsignup__imagetext-container p {
    font-family: var(--font-poppins);
    /* font-size: 1vw; */
    font-size: clamp(12px, 3.75vw, 18px);
    padding: 0 8px;
}
.newsignup__imagetext-container.selected img {
    border: 5px solid var(--color-orange);
}
.newsignup__imagetext-container.selected p {
    /* text-decoration: underline; */
    font-weight: 800;
}

.newsignup__create-account-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    /* padding-top: 5vh; */
    max-width: 90%;
    margin: 0 auto;
    
}
.newsignup__create-account-containers input {
    /* width: 15vmax; */
    width: clamp(110px, 15vw, 250px);
    /* height: calc(35vmin / 6); */
    height: 5vh;
    /* border: .5px solid lightgray; */
    border: none;
    border-radius: 8px;
    padding-left: 1.75%;
    font-family: var(--font-poppins);
    /* font-weight: 1000; */
    /* font-size: 1.5vmin; */
}
.newsignup__create-account-containers input::placeholder {
    /* color: gray;
    font-weight: normal;
    font-family: var(--font-poppins);
    font-size: 1.5vmin;
    position: relative;
    top: 50%;
    transform: translateY(-50%); */
    color: gray;
    font-family: var(--font-poppins);
}
.newsignup__create-account-containers input:focus {
    outline: none;
    border: 2px solid red;
}

.newsignup__create-account-row1,
.newsignup__create-account-row2,
.newsignup__create-account-row3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

/* .newsignup__create-account-row2 input {
    width: 100%;
} */

.newsignup__signup-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* padding-top: 8vh; */
}
.newsignup__signup-button-container button {
    font-family: var(--font-poppins);
    background-color: var(--color-orange);
    /* width: 15vw; */
    width: clamp(150px, 20vw, 320px);
    height: 5vh;
    border: none;
    border-radius: 40px;
    /* font-size: 1.2vh; */
    font-weight: 700;
    cursor: pointer;
    color: black !important;
}

.newsignup_button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vmin;
}

.emailconfirm__left-side-container {
    display: flex;
    flex-direction: column;
    font-family: var(--font-poppins);
    /* justify-content: flex-start; */
    flex: 1;
}

.emailconfirm__left-side-top-container {
    display: flex;
    flex-direction: column;
    /* flex: .4; */
    /* background-color: green; */
    align-items: center;
    gap: 2vh;
}
    .emailconfirm__left-side-top-container h1 {
        font-size: 3vw;
    }
    .emailconfirm__left-side-top-container p {
        font-size: .8vw;
    }

.emailconfirm__left-side-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    /* flex: .4; */
    /* background-color: red; */
}
.emailconfirm__left-side-image-container img {
    object-fit: contain;
    max-height: 100%; /* Make the image adjust its height to fit within the container */
    max-width: 100%; 
    /* height: 30%; */
    /* border: 1px solid red; */
}

.emailconfirm__left-side-bottom-container {
    display: flex;
    flex-direction: column;
    font-size: .8vw;
    /* flex: .2; */
    /* background-color: yellow; */
    align-items: center;
}

.loadingspinner {
    border: 8px solid var(--color-lightgray);
    border-top: 8px solid var(--color-orange);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: loadingspin 1s linear infinite;
}
@keyframes loadingspin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {
    .newsignup__right-side-container {
        display: none;
    }

    .newsignup__left-side-container {
        flex: 1;
    }

    .newsignup__signup-redirect {
        align-items: flex-end;
        flex-direction: column;
    }

    .newsignup__create-account-containers input {
        width: unset;
    }

    .newsignup__imagetext-container img {
        width: clamp(90px, 22vw, 150px);
    }
    /* .newsignup__imagetext-container p {
        font-size: clamp(12px, 1.5vw, 18px);
    } */

    .newsignup__create-account-row3 {
        flex-direction: column;
    }
}