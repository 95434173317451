.pricingsection__entire-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-poppins);
    width: 100%;
    height: 100%;
    padding: 0vh 2vw 2vh 2vw;
}

.pricingsection__top-area-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px;
}

.pricingsection__bottom-area-container {
    width: 100%;
}

.pricingsection__pricing-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 100px;
    row-gap: 50px;
    width: 100%;
}

.pricingsection__pricing-cards-container-break2 {
    display: none;
}

/* @media screen and (max-width: 1563px) and (min-width: 1146px) {
    .pricingsection__pricing-cards-container {
        display: none;
    }
}
@media screen and (min-width: 1563px) {
    .pricingsection__pricing-cards-container-break2 {
        display: none;
    }
}
@media screen and (max-width: 1146px) {
    .pricingsection__pricing-cards-container-break2 {
        display: none;
    }
} */

/* @media (max-width: 1563px) {
    .pricingsection__pricing-cards-container > :nth-child(3):nth-last-child(2),
    .pricingsection__pricing-cards-container > :nth-child(4):nth-last-child(1) {
        margin-left: auto;
    }
    
    .pricingsection__pricing-cards-container > :nth-child(4):nth-last-child(1) {
        margin-right: auto;
    }
} */
