* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    overflow: hidden;
}

.section__margin {
    padding: 4rem 6rem;
}

.signin__page-background {
    background-image: url('../../assets/stanford-background.png');
    background-size: 100vw 100vh;
    background-position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.signin__page-whitebox {
    background-color: white;
    opacity: 1.0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    /* horizontal distance between white container and right of page */
    margin-right: 6.5rem;
    height: 58rem;
    width: 46rem;
    overflow: hidden;
}

.signin__page-whitebox img {
    height: 120px;
    width: 120px;
}

.signin__page-signin-text {
    color: #000;
    font-family: var(--font-manrope);
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
    /* space between top of white box and signin title */
    margin-top: 3rem;
    /* space between signin title and email box */
    margin-bottom: 6rem;
}

.signin__page-emailpass-container {
    display: flex;
    flex-direction: column;
    /* space between emailpass-container and button */
    margin-bottom: 2rem;
}

.signin__page-emailpass-container input {
    width: 38rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* space between email and password textboxes */
    margin-bottom: 3rem;
    color: #9B9999;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01875rem;
    /* distance between text and left edge of textbox */
    padding: 0 0 0 1rem;
}

.signin__page-button {
    /* distance between password and signin button */
    margin-top: 2rem;
    display: flex;
}

.signin__page-button button {
    display: flex;
    width: 28rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 46px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: var(--color-white);
    border-width: 0;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.signin__page-button .selected-button {
    transition: background-color 0.3s, transform 0.3s;
}

.signin__page-button .selected-button:hover {
    transform: scale(1.05);
}

.signin__page-redirect-container {
    display: flex;
    /* distance between signin button and redirect button */
    margin-top: 5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.signin__page-redirect-container p {
    /* horizontal distance between the don't have an account message and the button */
    margin-right: 5rem;
    color: #000;
    text-align: center;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
    letter-spacing: 0.03125rem;
}

.signin__page-redirect-container button {
    color: var(--color-orange);
    text-align: center;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
    letter-spacing: 0.03125rem;
    border-width: 0;
    border-style: none;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
}