.tour__navbar-entire-container  {
    display: flex;
    background-color: #26153a;
    justify-content: center;
}

.tour__navbar {
    display: flex;
    justify-content: space-between;
    background-color: #26153a;
    align-items: center;
    /* padding: 2rem 6rem 2rem 6rem; */
    padding: 30px 6vw 30px 6vw;
    flex: 1;
    font-family: var(--font-poppins);
    max-width: 2560px;
}

.tour__navbar-links_logo {
    /* margin-right: 2rem; */
    display: flex;
    flex: .2;
    /* width: 10%; */
}

.navbar__logo {
    /* object-fit: cover; */
    /* should i use clamp here??? */
    /* width: 10vw; */
    width: clamp(80px, 10vw, 300px);
    height: 100%; /* Ensure the image fills the height of the container */
    object-fit: cover;
}

.tour__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex: .6;
}

.tour__navbar-sign {
    display: flex;
    /* use if sign up button is added back */
    /* justify-content: space-evenly; */
    justify-content: flex-end;
    align-items: center;
    overflow: visible;
    flex: .2;
}

.tour__navbar-links_container p {
    color: white;
    /* font-family: var(--font-manrope); */
    /* font-size: 20px; */
    font-size: clamp(12px, 1vw, 25px);
    font-weight: 400;
    /* line-height: 28px; */
    letter-spacing: 0.5px;
    text-transform: lowercase;
    /* margin: 0 3rem; */
    cursor: pointer;
}

.tour__navbar-sign p {
    color: white;
    /* font-family: var(--font-manrope); */
    /* font-size: 20px; */
    /* font-size: 1vw; */
    font-size: clamp(12px, 1vw, 25px);
    font-weight: 400;
    /* line-height: 28px; */
    letter-spacing: 0.5px;
    text-transform: lowercase;
    /* margin: 0 2rem; */
    font-family: var(--font-poppins);
    cursor: pointer;
    font-weight: 800;
}

.tour__navbar-sign button {
    display: flex;
    /* width: 120px; */
    /* height: 56px; */
    /* width: 8vw; */
    padding: 14px;
    justify-content: center;
    align-items: center;
    /* gap: 12px; */
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    /* backdrop-filter: blur(4px); */
    outline: none;
    cursor: pointer;
    color: var(--color-white);
    border-width: 0;
    /* font-size: 20px; */
    font-size: clamp(1rem, .8vw, 2rem);
    font-weight: 700;
    font-family: var(--font-poppins);
}

.navbar__signup-button:hover {
    color: var(--color-orange);
}

.tour__navbar-sign .selected-button {
    transition: background-color 0.3s, transform 0.3s;
}

.tour__navbar-sign .selected-button:hover {
    transform: scale(1.25);
    /* transform: scale(1.5); */
}

@media screen and (max-width: 550px) {
    .tour__navbar-links_container {
        display: none;
    }

    .tour__navbar-sign p {
        letter-spacing: unset;
    }
}