.newguidedisplay__entire-container {
    background-color: #dfedec;
    display: flex;
    flex-direction: column;
}

.newguidedisplay__text-and-images-container {
    display: flex;
    flex-direction: column;
    /* border: 2px solid white; */
    /* background-color: #E6E6E6; */
    background-color: #26153a;
    /* border-radius: 15px; */
    padding: 1vh 1vw;
    align-items: center;
    gap: 2vh;
    flex: .7;
}
.newguidedisplay__text-and-images-container h1 {
    display: flex;
    color: var(--color-orange);
    flex: .2;
    font-family: var(--font-poppins);
    padding: 10px 3vw;
    font-size: clamp(15px, 3vw, 60px);
}

.newguidedisplay__boxes-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.newguidedisplay__boxes-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 5vw;
}

@media screen and (max-width: 682px) {
    .newguidedisplay__boxes-row {
        justify-content: center;
        column-gap: 12vw;
    }
}
@media screen and (max-width: 396px) {
    .newguidedisplay__boxes-row {
        justify-content: center;
        padding: 0 0;
    }
}

/* @media screen and (max-width: 1760px) {
    .newguidedisplay__boxes-row {
        column-gap: 100px;
    }
}

@media screen and (max-width: 918px) {
    .newguidedisplay__boxes-row {
        column-gap: 100px;
    }
}

@media screen and (min-width: 623px) and (max-width: 918px) {
    .newguidedisplay__boxes-row {
        column-gap: 40px;
    }
} */

@media screen and (max-width: 430px) {
    .newguidedisplay__boxes-container {
        column-gap: 10px;
    }
}

@media screen and (min-width: 430px) and (max-width: 470px) {
    .newguidedisplay__boxes-container {
        column-gap: 70px;
    }
}

@media screen and (max-width: 430px) and (min-width: 370px) {
    .newguidedisplay__boxes-container {
        column-gap: 50px;
    }
}