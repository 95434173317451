.pricingcard__entire-container {
    display: flex;
    flex-direction: column;
    border: 4px solid var(--color-orange);
    border-radius: 15px;
    height: 480px;
    width: 300px;
    padding: 10px 10px 10px 8px;
    font-family: var(--font-poppins);
    /* color: #464444; */
}

.pricingcard__top-area-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.pricingcard__top-area-container button {
    border: none;
    width: 100%;
    height: 35px;
    border-radius: 50px;
    font-weight: 700;
    color: black;
    cursor: pointer;
    background-color: var(--color-orange);
    font-family: var(--font-poppins);
}

.pricingcard__bottom-area-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.pricingcard__feature-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* @media screen and (min-width: 1146px) and (max-width: 1562px) {
    .pricingcard__entire-container {
        height: 480px;
        width: 250px;
    }
} */