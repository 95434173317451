@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css?family=Satisfy');
@import url('https://fonts.googleapis.com/css2?family=Merriweather');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

:root {
  --font-manrope: 'Manrope', sans-serif;
  --font-satisfy: 'Satisfy', sans-serif;
  --font-merriweather: 'Merriweather', sans-serif;
  --font-poppins: "Poppins", sans-serif;
  --font-montserrat: "Montserrat", sans-serif;
  --color-white: #FFFFFF;
  --color-orange: #F4AE02;
  --color-dark-purple: #26153a;
  --color-lightgray: #F2F5F9;
  --color-grey-textbox_border: #D9D9D9;
  --color-grey-text_in_textbox: #9B9999;
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  --transparent-black: rgba(0, 0, 0, 0.294);
}

html {
  scroll-behavior: smooth;
  overflow: auto;
  font-family: var(--font-manrope);
}
