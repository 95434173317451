.confirmation__page-entire-container {
    display: flex;
    flex-direction: column;
    /* padding: 2rem 10rem; */
    font-family: var(--font-poppins);
    padding: 2rem;
}

.confirmation__page-top-area-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.confirmation__page-guide-rechoose-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
}

.confirmation__page-ranking-entire-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 8vw;
    row-gap: clamp(22px, 2vw, 36px);
    /* padding: 2vw; */
    padding: 1vw 2vw 2vw 2vw;
    overflow: visible;
}

.confirmation__page-bottom-area-container {
    padding-top: 32px;
    display: flex;
    flex-direction: row;
}

.confirmation__page-left-side-container {
    display: flex;
    flex-direction: column;
    flex: 1.2;
    /* padding-top: 1rem; */
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    align-items: center;
}

.confirmation__page-calendar-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-bottom: 2rem;
    gap: 1rem;
}

/* --- calendar styling START --- */

.react-calendar {
    /* width: 30rem; */
    width: 100%;
    min-width: 290px;
    /* max-width: 100%; */
    background-color: #fff;
    color: #0e3de5;
    border-radius: 15px;
    border: 2px solid #D9D9D9;
    font-family: var(--font-poppins);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    line-height: 1.125em;
}

.react-calendar__navigation button {
    color: var(--color-orange);
    min-width: 44px;
    background: none;
    /* font-size: 13px; */
    font-size: clamp(13px, 1vw, 16px);
    font-weight: 700;
    margin-top: 8px;
    font-family: var(--font-poppins);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #6f48eb;
    border-radius: 6px;
}

.react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}

.react-calendar__tile--active {
    background: #6f48eb;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #6f48eb;
    color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
}

.react-calendar__tile--range {
    background: #f8f8fa;
    color: #6f48eb;
    border-radius: 0;
}

.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #6f48eb;
    color: white;
}

.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #6f48eb;
    color: white;
}

/* .react-calendar__tile, */
/* .react-calendar__month-view__days__day, */
/* .react-calendar__month-view__days__day--weekend {
    color: black;
} */

/* --- calendar styling END --- */

.confirmation__page-time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding-bottom: 2rem;
}

.confirmation__page-time-selector-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
/* :where(.css-dev-only-do-not-override-fpg3f5).ant-picker {
    min-width: 170px;
} */

.ant-picker-input input::placeholder {
    font-family: var(--font-poppins);
    font-size: .875rem;
    color: #999;
    font-weight: bold;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-picker-dropdown .ant-picker-panel-container {
    box-shadow: none;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-picker .ant-picker-input >input {
    font-family: var(--font-poppins);
    font-weight: bold;
}

.confirmation__page-duration-container {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.confirmation__page-duration-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
}

.confirmation__page-duration-buttons-container button {
    /* width: 8rem; */
    width: 6vw;
    height: 2rem;
    background: var(--color-orange);
    border-radius: 15px;
    font-family: inherit;
    font-weight: bold;
    color: black;
    border: none;
    cursor: pointer;
}

.confirmation__page-duration-buttons-container button.selected {
    background-color: #ffa200;
    color: white;
}

.confirmation__page-summary-text {
    /* padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem; */
    text-align: center;
}

.confirmation__page-summary-text p {
    font-family: var(--font-poppins);
    font-size: 1.5rem;
}

.confirmation__page-right-side-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2;
    /* padding-top: 1rem; */
    /* padding-left: 1rem; */
}

.confirmation__page-right-side-personal-information-container {
    display: flex;
    flex-direction: column;
    font-family: var(--font-poppins);
    width: 100%;
    gap: 1rem;
    padding-left: 15%;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.confirmation__page-personal-information-info {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-left: 10px;
    /* align-items: flex-end; */
}
.confirmation__page-personal-information-info p {
    font-family: var(--font-poppins);
    font-weight: 700;
    font-size: clamp(12px, 2vw, 20px);
}
.confirmation__page-info-text-container {
    border: 2px solid var(--color-orange);
    font-family: var(--font-poppins);
    border-radius: 10px;
    /* font-weight: 700; */
    color: black;
    height: 40px;
    /* width: 50%; */
    padding-left: 5px;
}
.confirmation__page-info-text-container:focus {
    outline: none;
}

.confirmation__page-right-side-guide-rank-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: var(--font-poppins);
    gap: 1rem;
}

.confirmation__page-right-side-extra-information-container {
    padding-top: 3rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.confirmation__page-text-area-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    gap: 1rem;
}

.confirmation__page-text-area {
    width: 100%;
    /* max-height: 160px; */
    scroll-behavior: auto;
    min-height: 200px;
    padding: 10px;
    /* font-size: 1rem; */
    font-size: clamp(15px, 1vw, 20px);
    border: 2px solid var(--color-orange);
    border-radius: 15px;
    resize: vertical;
    font-family: var(--font-poppins);
}
.confirmation__page-text-area::placeholder {
    font-size: clamp(15px, 1vw, 20px);
}

.confirmation__page-right-side-payment-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding-left: 10%; */
}

.confirmation__page-total-amount-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.confirmation__page-payment-button {
    display: flex;
    flex-direction: column;
}

.confirmation__page-payment-button button {
    width: 15rem;
    padding: 1rem;
    border-radius: 15px;
    border: none;
    background: var(--color-orange);
    color: white;
    cursor: pointer;
}

#scroll-down-arrow {
    position: fixed;
    bottom: 10px;
    right: 1%;
    transform: translateX(-50%);
    color: var(--color-orange);
    z-index: 9999;
}

@media screen and (max-width: 1300px) {
    .confirmation__page-time-selector-container {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}

.react-calendar__month-view__days__day--weekend {
    color: black;
}
.react-calendar__tile--range {
    color: #6f48eb;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
    color: white;
}