.textbox__boxstyling_long {
    display: flex;
    flex-direction: column;
}

.textbox__boxstyling_long p {
    font-family: var(--font-manrope);
    font-weight: 700;
    color: black;
    margin-bottom: 1rem;
}

.box__long {
    width: 60rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    background: #ffffffc7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 3rem;
    color: var(--color-grey-text_in_textbox);
    font-family: var(--font-manrope);
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0 0 0 1rem;
}