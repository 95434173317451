.whyus__entire-container {
    width: 100%;
    /* changes vertical distance between steps section and carousel */
    /* height: 60vh; */
    /* height: 100vh; */
    align-items: center;
    /* padding: 2rem 10rem; */
}
  
.whyus__carousel {
    width: 100%;
    /* height: 100%; */
    height: 40rem;
    position: relative;
}
  
.card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    /* width: 25rem;
    height: 35rem; */
    width: 23%;
    height: 85%;
    background-color: #f0f0f0;
    display: flex;
    cursor: pointer;
    z-index: 0;
    opacity: 0;
    border-radius: 15px;
    transition: 500ms;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #D9D9D9;
}

.inside-card-entire-container {
    flex-direction: column;
    margin-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
  
.card--active {
    opacity: 1;
    transform: scale(1);
    z-index: 99;
}
  
.card--left {
    transform: translateX(-125%) scale(0.8);
    transition: 500ms;
    opacity: 0.3;
}
  
.card--right {
    transform: translateX(125%) scale(0.8);
    transition: 500ms;
    opacity: 0.3;
}

.reason-text {
    font-size: 2rem;
    font-weight: bold;
    font-family: var(--font-poppins);
    color: var(--color-orange);
}

.subtext-text {
    font-size: 1.5rem;
    font-family: var(--font-poppins);
    color: black;
}