/* .guideonboard__formcontrollabel-style {
    padding-left: 11px;
} */

.tgo-custom-checkbox-label {
    font-family: var(--font-poppins);
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
}
  
.tgo-custom-checkbox-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin: 0;
    /* margin-right: 8px; */
    position: relative;
    outline: none;
    transition: border-color 0.3s;
    cursor: pointer;
}
.tgo-custom-checkbox-input:checked {
    border-color: #007bff;
    background-color: var(--color-orange);
}
  
.tgo-custom-checkbox-text {
    font-size: 14px;
    font-size: clamp(.5rem, .8vw, 20rem);
    /* color: #333; */
    color: black;
}

.bold-text {
    font-weight: 700;
}