.dnd__container-entire-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dnd__container-image-container {
    width: 20rem;
    align-items: center;
}

.dnd__container-image-container img {
    width: 100%;
}

.dnd__container-all-ranking-container {
    display: flex;
    flex-direction: row;
    gap: 5rem;
}

.dnd__container-right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dnd__container-right-side p {
    font-size: 3rem;
    font-weight: 700;
}

#container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.rankingCards {
    /* border: 3px solid; */
    /* border-radius: 15px; */
    /* padding: 1rem; */
    /* width: 50rem; */
    /* border-color: var(--color-orange); */
    cursor: grab;
}

.rankingCardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rankingCardContainer img {
    width: 2rem;
}

.rankingCardContainer p {
    font-family: var(--font-poppins);
    color: black;
    font-weight: 700;
}