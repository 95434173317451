* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.section__margin {
    padding: 4rem 6rem;
}

.signup__page-background {
    background-image: url('../../assets/stanford-background.png');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
}

.signup__page-whitebox {
    background-color: white;
    opacity: 1.0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    /* horizontal distance between white container and right of page */
    margin-right: 6.5rem;
    height: 58rem;
    width: 46rem;
    overflow: hidden;
}

.signup__page-whitebox img {
    height: 120px;
    width: 120px;
}

.signup__page-create-account-container {
    margin-top: 3rem;
    display: flex;
    align-items: center;
}

.signup__page-create-account-container p {
    color: #000;
    font-family: var(--font-manrope);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.signup__page-create-account-container-buttons {
    display: flex;
    flex-direction: column;
    /* distance between 'create account for' and buttons */
    margin-left: 2rem;
    justify-content: space-between;
}

.signup__page-create-account-container-buttons button:nth-child(2) {
    /* vertical distance between buttons */
    margin-top: .5rem;
}

.signup__page-create-account-container-buttons button {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 46px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    border-width: none;
    border-style: none;
    border-color: none;
}

.signup__page-create-account-container-buttons p {
    display: flex;
    width: 12rem;
    height: 1.5rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: var(--font-manrope);
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
}

.signup__page-create-account-container-buttons .selected-button {
    background: #90f109;
    transition: background-color 0.3s, transform 0.3s;
}
  
.signup__page-create-account-container-buttons .selected-button:hover {
    transform: scale(1.05);
}

.signup__page-emailpass-container {
    display: flex;
    flex-direction: column;
    /* top right bottom left */
    margin: 2rem 0 1rem 0;
}

.signup__page-emailpass-container input {
    width: 38rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* space between email and password textboxes */
    margin-bottom: 2rem;
    color: #9B9999;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01875rem;
    /* distance between text and left edge of textbox */
    padding: 0 0 0 1rem;
}

.signup__page-button button {
    display: flex;
    width: 28rem;
    height: 3.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 46px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: var(--color-white);
    border-width: 0;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.signup__page-button .selected-button {
    transition: background-color 0.3s, transform 0.3s;
}

.signup__page-button .selected-button:hover {
    transform: scale(1.05);
}

.signup__page-alternatives-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    /* vertical distance between tac and logos */
    margin-bottom: 1rem;
}

.signup__page-alternatives-container p {
    color: #000;
    text-align: center;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: .03125rem;
    margin-bottom: 1rem;
}

.signup__page-alternatives-logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

/* horizontal distance between each logo */
.signup__page-alternatives-logos img:not(:last-child) {
    margin-right: 8rem;
}

.signup__page-alternatives-logos img {
    height: 2.5rem;
    width: 2.5rem;
}

.signup__page-tac {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* vertical distance between tac and images */
    padding-top: 1rem;
}

.signup__page-tac p {
    color: #373737;
    text-align: center;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
    letter-spacing: .03125rem;
}

.signup__page-tac a {
    display: inline;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-weight: 700;
}

.orange-link {
    color: var(--color-orange);
    text-decoration: none;
}

.signup__page-redirect-container {
    display: flex;
    /* distance between tac and redirect button */
    margin-top: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.signup__page-redirect-container p {
    /* horizontal distance between the have an account message and the button */
    margin-right: 5rem;
    color: #000;
    text-align: center;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
    letter-spacing: 0.03125rem;
}

.signup__page-redirect-container button {
    color: var(--color-orange);
    text-align: center;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.125rem;
    letter-spacing: 0.03125rem;
    border-width: 0;
    border-style: none;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
}