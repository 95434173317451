.newsearch__entire-container {
    display: flex;
    flex-direction: column;
    font-family: var(--font-poppins);
    /* background-color: var(--color-dark-purple); */
    /* height: 100vh; */
}

.newsearch__top-container {
    display: flex;
    flex-direction: row;
    overflow: visible;
    /* flex: 1; */
}

.newsearch__top-left-image-container {
    display: flex;
    width: 100%;
}
.newsearch__top-left-image-container img {
    object-fit: cover;
    object-position: 0 0;
    /* height: 20%; */
    /* width: 10%; */
}

.newsearch__criteria-header-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: .25;
}
/* .newsearch__criteria-header-container h1 {
    color: var(--color-orange);
    font-size: clamp(10px, 3vw, 30px);
}
.newsearch__criteria-header-container p {
    color: black;
    font-size: 1.75vmin;
    text-decoration: underline;
} */

.newsearch__header-container {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: center;
    text-align: center;
    flex: .75;
    overflow: visible;
}
.newsearch__header-container h1 {
    color: var(--color-orange);
    font-size: clamp(25px, 4vw, 50px);
    text-align: center;
}
.newsearch__header-container p {
    color: black;
    font-size: clamp(12px, 2vw, 15px);
}

.newsearch__bottom-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.newsearch__left-side-container {
    display: flex;
    flex-direction: column;
    padding: 1vh 0 0 .8%;
    flex: .20;
}

.newsearch__right-side-container {
    display: flex;
    flex-direction: column;
    flex: .80;
    padding-top: 1vh;
}

.newsearch__search-criteria-area {
    display: flex;
    flex-direction: column;
}


.newsearch__sentence-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: var(--font-poppins);
    /* background-color: grey; */
    /* flex: var(--newsearch-sentence-percentage); */
    padding: 0vh 8vw;
    text-align: center;
    /* width: 80%; */
}
.newsearch__search-results-container {
    display: flex;
    flex-direction: row;
    /* background-color: magenta; */
    flex-wrap: wrap;
    /* flex: var(--newsearch-search-results-container); */
    justify-content: space-evenly;
    /* gap: 2vw; */
    column-gap: .5vw;
    row-gap: 2vw;
    padding: 2.5vh 3vw;
    overflow: scroll;
    /* border: 1px solid red; */
    /* width: 100%; */
    /* height: 75vh; */
}

.newsearch__search-prompt-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid red;
    padding: 0 1%;
    width: 100%;
    height: 75vh;
}