.footer__link-text {
    color: #9fa1a7;
    /* font-family: var(--font-manrope); */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.footer__link-text:hover {
    color: var(--color-orange);
}