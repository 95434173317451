.newtoppart__total-entire-container {
    display: flex;
    background-color: #26153a;
    justify-content: center;
    min-height: 90vh;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.newtoppart__entire-container {
    display: flex;
    flex-direction: column;
    background-color: #26153a;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* gap: 5vh; */
    font-family: var(--font-poppins);
    max-width: 2560px;
    padding: 0 3vw 4vh 3vw;
    /* min-height: 90vh; */
    /* height: 90vh; */
}

.newtoppart__main-text {
    display: flex;
    /* height: 100%; */
    /* width: 100vw; */
    /* justify-content: space-between; */
    align-items: center;
}
.newtoppart__main-text h1 {
    color: white;
    width: 70vw;
    /* font-size: 4vw; */
    font-size: clamp(15px, 4.2vw, 110px);
    /* padding: 0 1vw; */
}
.newtoppart__main-text span {
    /* color: #0feb3f; */
    color: var(--color-orange);
    /* font-size: 4vw; */
    font-size: clamp(15px, 4.2vw, 110px);
    /* font-family: var(--font-satisfy); */
}
.newtoppart__main-text img {
    width: 55%;
}

.newtoppart__submessage {
    color: var(--color-orange);
    font-family: var(--font-poppins);
    /* font-size: 1.5vw; */
    font-size: clamp(18px, 2vw, 40px);
    font-weight: bold;
    margin-bottom: 10px;
}

.newtoppart__join-us-button {
    display: flex;
    flex-direction: row;
}
.newtoppart__join-us-button button {
    border: none;
    background-color: var(--color-orange);
    color: white;
    width: clamp(100px, 10vw, 180px);
    height: clamp(30px, 3vw, 54px);
    border-radius: 15px;
    font-size: clamp(10px, 1vw, 50px);
    font-family: var(--font-poppins);
    font-weight: bold;
    cursor: pointer;
}

.newtoppart__joinus {
    transition: background-color .3s, transform .3s;
}
.newtoppart__joinus:hover {
    transform: scale(1.25);
}

@media screen and (max-width: 430px) { /* for mobile phones */
    .newtoppart__entire-container {
        display: flex;
        flex-direction: column;
        padding: none;
        justify-content: unset;
        gap: 10%;
    }
    .newtoppart__main-text img {
        display: none;
    }

    .newtoppart__main-text h1,
    .newtoppart__main-text span {
        font-size: clamp(30px, 9vw, 40px);
    }
}