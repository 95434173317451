.cardguide__container {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 20rem;
    height: 30rem; */
    
    border-radius: 15px;
    z-index: 2;
    
    min-width: 30%;
    max-width: 30%;
    flex-grow: 1;
    /*  width: 18vw; */
    /* height: 50vw; */
}

.cardguide__image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.cardguide__image img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.cardguide__overlay {
    position: absolute;
    /* top: 75%; */
    bottom: 0%;
    height: 25%;
    width: 100%;
    /* height: 100%; */
    fill: linear-gradient(359deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.12) 100%);
    box-shadow: 10px 10px 23px 0px rgba(62, 62, 62, 0.18) inset, 0px 2px 6px 0px rgba(62, 62, 62, 0.05) inset, 0px 1px 0px 0px rgba(62, 62, 62, 0.05) inset, 0px -10px 10px 0px rgba(62, 62, 62, 0.40) inset;
    backdrop-filter: blur(1rem);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    z-index: 0;
    /* justify-content: center; */
}

.cardguide__bottom-area {
    display: flex;
    flex-direction: column;
    /* margin-top: 1rem;
    margin-left: 1rem; */
    padding-left: 6%;
    padding-top: 2%;
    justify-content: center;
}

.cardguide__name-major-container {
    display: flex;
    flex-direction: column;
}

.cardguide__name {
    color: var(--color-orange);
    font-family: var(--font-manrope);
    /* font-size: 1.5rem; */
    font-size: 2vh;
    /* font-size: 1%; */
    /* font-size: 150%; */
    /* font-size: 2rem; */
    font-weight: 400;
    letter-spacing: 0.3px;
}

.cardguide__major {
    color: white;
    font-family: var(--font-manrope);
    /* font-size: 1rem; */
    font-size: 2vh;
    /* font-size: 90%; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
}

.cardguide__year {
    color: var(--color-orange);
    font-family: Manrope;
    /* font-size: .9rem; */
    font-size: 2vh;
    /* font-size: 80%; */
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-align: right;
    padding-right: 2rem;
}

.cardguide__university-oval-container {
    border-radius: 100px;
    background: rgba(98, 255, 0, 0.22);
    backdrop-filter: blur(7.5px);
    height: 2rem;
    width: 6rem;
    position: relative;
    bottom: 4rem;
    left: 13rem; /* 14 rem pushes it to right edge of box */
    z-index: 3;
}