.newsignin__total-background {
    width: 100vw;
    height: 100vh;
    /* background: linear-gradient(rgba(248, 248, 248, 0.5), rgba(236, 230, 230, 0.5)), url('../../assets/water.jpg'); */
    /* background: var(--gradient-bar); */
    background: radial-gradient(101.47% 138.81% at 36.30% 39.26%, rgba(255, 184, 0, 0.60) 0%, rgba(239, 161, 0, 0.00) 100%);
    /* background: var(--color-dark-purple); */
    /* background: var(--color-orange); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    font-family: var(--font-poppins);
}

.center-container {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);  /* Adding the blur effect */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centered-box {
    --vertical-margin: 6vh; 
    --horizontal-margin: 8vw;

    /* background-color: #F2F5F9; */
    /* background-color: var(--color-orange); */
    /* border: 3px solid white; */
    border-radius: 40px;
    box-sizing: border-box;
    margin: var(--vertical-margin) var(--horizontal-margin); 
    width: calc(100vw - 2 * var(--horizontal-margin)); 
    height: calc(100vh - 2 * var(--vertical-margin));
    max-width: 70vw;
}

.newsignin__entire-container {
    display: flex;
    flex-direction: row;
    padding: 3vh 2vw;
    height: 100%;
    justify-content: space-between;
    /* background-color: var(--color-orange); */
    /* background-color: var(--color-dark-purple); */
    /* gap: 5vw; */
}

.newsignin__left-side-container {
    display: flex;
    /* background-color: red; */
    /* background: #D58FFE; */
    /* background: var(--color-orange); */
    flex: .4;
    height: 100%;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    overflow: visible;
}
.newsignin__left-side-container img {
    width: 100%;
}

.newsignin__right-side-container {
    display: flex;
    flex-direction: column;
    overflow: visible;
    flex: .6;
}

.newsignin__signup-redirect {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 2vw;
    gap: 2vw;
    font-size: clamp(12px, 1.5vw, 18px);
    font-size: clamp(8px, 0.75vw, 12px);
}

.newsignin__signin-actual-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    height: 100%;
}

.newsignin__header-text-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1vh;
    padding-bottom: 10%;
    padding-left: 10px;
    padding-right: 10px;
}

.newsignin__login-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    padding-bottom: 3vh;
}

.newsignin__login-containers input {
    /* width: 25vw; */
    width: clamp(190px, 22vw, 300px);
    height: 5vh;
    border: none;
    border-radius: 8px;
    padding-left: 1.75%;
    font-family: var(--font-poppins);
    /* font-size: 1.5vmin; */
    /* font-size: clamp(10px, 1.5vw, 18px); */
}
.newsignin__login-containers input::placeholder {
    color: gray;
    font-weight: 0;
    /* font-size: 1.5vmin; */
    /* font-size: clamp(10px, 1.5vw, 18px); */
    font-family: var(--font-poppins);
}
.newsignin__login-containers input:focus {
    outline: none;
    border: 2px solid var(--color-orange);
}

.newsignin__forgot-password-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10vh;
    padding-right: 2vw;
}

.newsignin__signin-button-container {
    display: flex;
    justify-content: center;
    overflow: visible;
}
.newsignin__signin-button-container button {
    /* width: 20vw; */
    width: clamp(150px, 20vw, 345px);
    height: 5vh;
    font-weight: 700;
    font-family: var(--font-poppins);
    border: none;
    /* background: #FE605D; */
    background: var(--color-orange);
    color: black !important;
    border-radius: 40px;
    cursor: pointer;
    z-index: 9999;
}

.newsignin__signin-messages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: clamp(9px, 0.75vw, 12px);
    color: red;
    padding-top: 10px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .newsignin__left-side-container {
        display: none;
    }

    .newsignin__right-side-container {
        flex: 1;
    }

    .newsignin__signup-redirect {
        align-items: flex-end;
        flex-direction: column;
    }

    .newsignin__login-containers input {
        /* border: 1px solid orange; */
        /* width: 50vw; */
        /* width: unset; */
        width: clamp(180px, 55vw, 300px);
        /* height: clamp(22px, 5vw, 30px); */
    }

    .newsignin__forgot-password-container {
        padding-bottom: 5vh;
    }

    .newsignin__signin-actual-container {
        justify-content: space-evenly;
    }

    .newsignin__signin-messages {
        padding-top: 0px;
    }
}