.multiselect-wrapper {
    width: 100%;
    height: 100%;
}
.multiselect-wrapper.is-active {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.multiselect__control {
    display: flex;
    border: 5px solid var(--color-orange);
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
    border-radius: 15px;
    /* padding-left: 6px;
    padding-right: 15px; */ 
    padding: 6px 10px 6px 10px;
    background-color: #fff;
    /* min-height: 45px; */
    min-height: 15vh;
    position: relative;
    align-items: center;
    cursor: pointer;
    /* justify-content: center; */
}

/* .multiselect__remove-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
} */
/* .multiselect__arrow-icon {
    position: absolute;
    right: 10px;
    top: 20px;
    transform: translateY(-50%);
    color: #8c8c8c;
    font-size: 14px;
} */

.multiselect__placeholder {
    color: #666;
}
.multiselect__placeholder.is-hidden {
    display: none;
}

.multiselect__search-area {
    /* font-family: var(--font-manrope); */
    line-height: 45px;
    padding: 0 30px 0 15px;
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.multiselect__search-input {
    font-family: var(--font-poppins);
    background: 0px center;
    border: 0px;
    font-size: inherit;
    opacity: 1;
    outline: 0px;
    padding: 0px;
    width: 100%;
}

/* .multiselect__search-icon {
    color: #8c8c8c;
    position: absolute;
    right: 15px;
    top: 15px;
} */

.multiselect__result-area {
    border: 1px solid var(--color-orange);
    border-top: none;
    background-color: #fff;
    display: none;
    max-height: 35vh;
    overflow-y: scroll;
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    border-radius: 15px;
}
.multiselect__result-area.is-active {
    display: block;
}

.multiselect-choices {
    display: inline-block;
}
.multiselect-choices__item {
    display: inline-block;
    /* color: #eb1290; */
    font-family: var(--font-poppins);
    /* color: var(--color-orange); */
    color: white;
    /* color: black; */
    font-weight: 700;
    margin-right: 5px;
    padding: 8px 9px;
    /* margin: 0 5px 5px 0; */
    /* background-color: #daf2fc;
    border: 1px solid #cef0ff; */
    /* background-color: rgb(122, 122, 122); */
    /* background-color: rgb(33, 234, 33); */
    /* background-color: blue; */
    background-color: var(--color-orange);
    border-radius: 15px;
    line-height: 1;
}

.multiselect-results__item {
    line-height: 45px;
}
.multiselect-results__item:hover {
    background-color: #f4af0254;
}
.multiselect-results__item + .multiselect-results__item {
    border-top: 1px solid #e0e0e0;
}
.multiselect-results__item.is-active {
    /* background-color: #f2fafd; */
    /* border: 1px solid #c7e2ec; */
    background-color: #f4af0284;
    border: 1px solid var(--color-orange);
    margin: 0 -1px;
}
.multiselect-results__item.is-active:first-child {
    /* border-top: 1px solid red; */
    border-top: none;
}
.multiselect-results__item.is-active:last-child {
    margin-bottom: -1px;
}
.multiselect-results__item.is-active + .multiselect-results__item {
    border-top: none;
}
.multiselect-results__item.is-highlighted {
    /* background-color: #f4af0284; */
    border: 2px dashed var(--color-orange);
    /* background-color: none; */
    /* background-color: grey; */
}

.custom-checkbox {
    display: none;
}
.custom-checkbox-label {
    position: relative;
    padding: 0 0 0 45px;
    display: block;
    cursor: pointer;
    font-family: var(--font-poppins);
    text-transform: lowercase;
}
.custom-checkbox-label::before {
    /* font-family: var(--font-manrope); */
    width: 16px;
    height: 16px;
    content: "";
    position: absolute;
    left: 14px;
    top: 14px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    /* border-radius: 100%; */
    border-radius: 30%;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
}
.custom-checkbox:checked + .custom-checkbox-label::before {
    /* content: "\f00c"; */
    /* background-color: rgb(242, 26, 141); */
    /* background-color: #df6100; */
    background-color: var(--color-orange);
    color: #fff;
    border-color: #2200ff;
    border-width: 2px;
}