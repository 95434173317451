/* body {
    max-width: 1890px;
    margin: 0 auto;
} */

.tgdashboard__entire-container {
    /* padding: 4rem 10rem; */
    padding-top: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 1rem;
    overflow: visible;
    max-width: 1500px;
    margin: 0 auto;
}

.tgdashboard__dashboard-text-and-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.tgdashboard__dashboard-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tgdashboard__dashboard-dashboard-text {
    color: #F4AE02;
    font-family: var(--font-manrope);
    font-size: 1.75rem;
    font-weight: 700;
}

.tgdashboard__dashboard-name-text {
    color: black;
    font-family: var(--font-manrope);
    font-size: 1.75rem;
    font-weight: 700;
}

.tgdashboard__dashboard-image-container img {
    width: 15rem;
    height: 100%;
}

.tgdashboard__bottom-area-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
}

.tgdashboard__information-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
}

.tgdashboard__right-side-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 2.5;
}

.tgdashboard__good-tour-definition {
    /* background: radial-gradient(circle at center, red, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-family: var(--font-poppins);
    color: var(--color-orange);
    /* cursor: pointer; */
    font-weight: 700;
}

.tgdashboard__table-container {
    overflow:visible;
    flex: 3;
}