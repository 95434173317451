* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-orange);
}

/* anchor text */
a {
    color: unset;
    text-decoration: none;
}