.step_component-container {
    display: flex;
    flex-direction: column;
    /* background: lightgreen; */
    width: 15vw;
    /* height: 30rem; */
    /* align-items: center; */
    height: 100%;
}

.step_component-image-container {
    /* width: 20rem;
    height: 20rem; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step_component-img img {
    width: auto;
    height: auto;
}

.step_component-number {
    font-family: var(--font-poppins);
    color: var(--color-orange);
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.step_component-text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* align-items: center; */
    font-family: var(--font-manrope);
}

.step_component-stepname {
    font-weight: 700;
}

/* arjun laptop is 1440 px */
@media screen and (min-width: 1441px) {
    .step_component-container {
        width: 10vw;
    }
}