.testimonials__entire-container {
    display: flex;
    flex-direction: column;
    /* width: 30rem; */
    /* height: 24rem; */
    width: 85%;
    /* height: 100%; */
    max-height: 25rem;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #9B9999;
    letter-spacing: 0.01875rem;
    padding-left: 8%;
    padding-top: 5%;
    padding-right: 8%;
    margin-bottom: 2%;
}

.testimonials__text-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* margin-bottom: 2rem; */
    padding-bottom: 8%;
}

.testimonials__testimonial {
    /* padding-top: 2rem;
    padding-right: 3rem; */
    color: #333;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0.3px;
}

.testimonials__university {
    color: black;
    font-family: var(--font-manrope);
    font-weight: 700;
}

.testimonials__person-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 5rem; */
    gap: 20%;
    padding-bottom: 6%;
}

.testimonials__image-wrapper img {
    height: 8rem;
    width: auto;
}

.testimonials__personal-text-section {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.testimonials__name {
    font-family: var(--font-manrope);
    color: var(--color-orange);
    font-weight: 1000;
}

.testimonials__location {
    font-family: var(--font-manrope);
    color: black;
}