.landingpfpcontainer__entire-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 8vw;
    padding-right: 8vw;
    gap: 1rem;
    font-family: var(--font-poppins);
    background-color: #dfedec;
    border-radius: 40px;
}

.landingpfpcontainer__text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 15px;
}

.landingpfpcontainer__text-left-container {
    display: flex;
    align-items: center;
    /* flex: .5; */
}
.landingpfpcontainer__text-left-container h1 {
    color: var(--color-orange);
    font-size: 2.5vw;
    font-size: clamp(15px, 2.2vw, 65px);
    text-align: center;
}

.landingpfpcontainer__name-pulse {
    display: inline-flex;
    animation: pulseAnimation 2s infinite;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    padding: 0px 10px;
}

.landingpfpcontainer__text-right-container {
    display: flex;
    flex-direction: column;
    flex: .5;
}

.landingpfpcontainer__text-right-container-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 1rem; */
    gap: 7px;
}
.landingpfpcontainer__text-right-container-element img {
    /* height: 30px; */
    height: clamp(10px, 2vw, 30px)
}
.landingpfpcontainer__text-right-container-element p {
    /* font-size: 1vw; */
    font-size: clamp(6px, 1vw, 50px);
}

@keyframes pulseAnimation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
}

@media screen and (max-width: 430px) {
    .landingpfpcontainer__text-container {
        /* justify-content: center; */
        /* text-align: center; */
    }
    .landingpfpcontainer__text-left-container h1 {
        text-align: unset;
    }
    .landingpfpcontainer__text-left-container {
        flex: 1;
    }
}