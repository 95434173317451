.about_page__entire-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: var(--font-poppins);
}

.about_page__top-area-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    background-color: var(--color-dark-purple);
    padding-top: 15px;
}
.about_page__top-area-container h1 {
    color: white;
    font-size: clamp(15px, 4.2vw, 110px);
}

.about_page__middle-area-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-dark-purple);
    padding: 0 15px;
}
.about_page__image-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    gap: 10px;
}
.about_page__middle-area-container img {
    width: 100%;
    max-width: 1000px;
    max-height: 1000px;
    margin: 0 auto;
    height: auto;
    border-radius: 40px;
}

.about_page__paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* text-align: center; */
    padding: 25px 15px;
    /* width: 1000px; */
}
.about_page__paragraph p {
    color: white;
    font-size: clamp(12px, 2vw, 20px);
}

@media screen and (min-width: 1000px) {
    .about_page__paragraph {
        width: 1000px;
    }
}