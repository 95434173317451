.appointment-table {
    width: 100%;
    border-radius: 15px;
    border: 1px solid #F4AE02;
    background: #FFF;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.25); 

}

table {
    width: 100%;
    border: none;
    border-collapse: separate; 
}

.top_row {
    color: var(--color-orange);
}

tr {
    width: max-content;
}

th {
    border-bottom: 1px solid #F4AE02;
    background: #FFF;
    box-shadow: 0px 4px 0px 0px rgba(107, 107, 107, 0.25);
}

td {
    font-weight: 600;
}

th, td {
    text-align: left;
    width: max-content; 
    padding: 10px 17px;
}

th:first-child {
  border-radius: 10px 0 0 10px;  
  border-bottom: 1px solid #F4AE02
}

th:last-child {
  border-radius: 0 10px 10px 0;
  border-bottom: 1px solid #F4AE02
}

.tgdashboard__nameStyling {
    color: #0019FF;
    font-weight: 600;
}

.tgdashboard__moneyStyling {
    color: #2FD92B;
    font-weight: 600;
}
