.imagetourist__entire-container {
    width: 40rem;
    height: 30rem;
    cursor: pointer;
    overflow: hidden;
    transition: height 0.3s;
  }
  
  .imagetourist__entire-container.expanded {
    height: 60rem;
    background: green; /* Add background color for expanded state */
  }
  
  .imagetourist__name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: lightblue;
  }
  
  .imagetourist__name p {
    font-size: 1.5rem;
  }
  
  .imagetourist__image {
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: height 0.3s;
  }
  
  .imagetourist__image.show {
    height: 100%;
  }
  
  .imagetourist__image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  