.squareprofilecard__entire-container {
    display: flex;
    flex-direction: column;
    /* width: 35vmin;
    height: 35vmin;
    min-width: 230px;
    min-height: 230px; */
    width: clamp(230px, 35vw, 360px);
    height: clamp(230px, 35vw, 360px);
    border-radius: 15px;
    border: 2px solid #CACACA;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
    padding: 1%;
    justify-content: space-between;
}
.squareprofilecard__entire-container.favoriteActive {
    border: 10px solid orange;
}

.squareprofilecard__top-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    /* width: 100%; */
    /* height: 40%; */
}

.squareprofilecard__image-container {
    display: flex;
    /* border-radius: 15px; */
    /* border: 1px solid #CACACA; */
    /* height: 100%; */
    /* width: 40%; */
    flex: .5;
    justify-content: center;
    /* position: relative; */
}
.squareprofilecard__image-container img {
    object-fit: cover;
    object-position: 0 0;
    max-height: 70%;
}

.squareprofilecard__top-area-right-side-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex: .5;
}

.squareprofilecard__tags-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    gap: 10px;
    height: 100%;
}

.squareprofilecard__tags-container-row1,
.squareprofilecard__tags-container-row2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* .squareprofilecard__tags-container > * { 
    width: calc(50% - .3vw);
    box-sizing: border-box;
} */

/* .squareprofilecard__seemore-button {
    font-family: var(--font-manrope);
    border: none;
    background: var(--color-orange);
    border-radius: 40px;
    font-weight: 800;
    height: 2.5vmin;
    cursor: pointer;
} */

.squareprofilecard__bottom-area {
    display: flex;
    flex-direction: column;
    /* gap: .8vmin; */
    gap: .4vw;
    padding-bottom: 5px;
}

.squareprofilecard__major-container,
.squareprofilecard__classyear-container,
.squareprofilecard__hometown-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.smallFont { /* smallest is 5 */
    /* font-size: 10px; */
    font-size: clamp(7px, 1vw, 10px);
}
.mediumFont {
    font-size: 11px;
    font-size: clamp(11px, .825vw, 14px);
}
.largeFont {
    font-size: 14px;
    font-size: clamp(13px, 1.05vw, 16px);
}
.extraLargeFont { /* largest is length 43 */
    font-size: 16px;
}

.squareprofilecard__title-text {
    /* font-size: 1.75vmin; */
    font-size: clamp(13px, .85vw, 15px);
    color: grey;
}
.squareprofilecard__info-text {
    /* font-size: 1.75vmin; */
    font-weight: 700;
    color: black;
}

.squareprofile__bottom-most-area {
    display: flex;
    flex-direction: column;
}

.squareprofilecard__favorite-button {
    font-family: var(--font-manrope);
    border: none;
    background: var(--color-orange);
    border-radius: 40px;
    font-weight: 800;
    /* height: 2.5vmin; */
    height: clamp(18px, 2vw, 24px);
    cursor: pointer;
}