.newtestimonials__entire-container {
    display: flex;
    flex-direction: column;
}

.newtestimonials__text-area-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: var(--font-poppins);
}

.newtestimonials__text-area-container h1 {
    color: var(--color-orange);
}

.newtestimonials__text-area-container p {
    font-weight: 300;
}

.newtestimonials__testimonial-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* padding-top: 2rem;
    padding-bottom: 2rem; */
}

.newtestimonials__testimonial-entire-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.newtestimonials__testimonial-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    width: 30vw;
    height: 30vh;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    gap: 1%;
    justify-content: space-between;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: .5vh;
    padding-bottom: 4vh;
}
.newtestimonials__testimonial-box p {
    font-family: var(--font-poppins);
    font-weight: 700;
}
.newtestimonials__testimonial-box img {
    width: 20%;
    height: auto;
}

.newtestimonials__personal-information-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
}
.newtestimonials__personal-information-container img {
    width: 8vw;
}
.newtestimonials__personal-information-container p {
    font-family: var(--font-poppins);
    color: var(--color-orange);
}

/* arjun laptop is 1440 px */
@media screen and (min-width: 1441px) {
    .newtestimonials__testimonial-box {
        justify-content: space-between;
        width: 15vw;
        height: 20vh;
        padding-top: 1vh;
        padding-bottom: 3vh;
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .newtestimonials__personal-information-container img {
        width: 5vw;
    }
}