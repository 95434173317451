.pricingpage__entire-container {
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    /* height: 100%; */
    /* height: 100vh; */
    min-height: 100vh;
    font-family: var(--font-poppins);
    justify-content: space-between;
    background-color: var(--color-dark-purple);
}

.pricingpage__top-area-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    overflow: scroll;
    gap: 50px;
    padding-top: 10vh;
    /* flex: .75; */
}
.pricingpage__top-area-container h1 {
    font-size: clamp(20px, 6vw, 95px) !important;
    padding: 50px 20px;
    font-weight: 700 !important;
    color: var(--color-orange);
}

.pricingpage__bottom-area-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* flex: .25; */
}
.pricingpage__bottom-area-container img {
    height: clamp(50px, 15vw, 230px);
    width: clamp(50px, 15vw, 230px);
}
.pricingpage__bottom-area-container p {
    color: var(--color-orange);
    font-family: var(--font-poppins);
    justify-content: center;
    font-size: clamp(10px, 2vw, 20px);
    padding: 0 20px;
    text-align: center;
}