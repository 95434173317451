.modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 250, 250, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(9px);
}

.modal__profile-display-page {
    background-color: transparent;
    z-index: 99999;
    /* transform: scale(.75); */
    transform: scale(.8);
}

/* arjun laptop is 1440 px
@media screen and (min-width: 1441px) {
    .modal__profile-display-page {
        transform: scale(1);
    }
} */