.verticalphotoloop__entire-container {
    background-color: transparent;
    height: 100%;
}
  
  @keyframes slideVertical {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  .verticalphotoloop__logos {
    overflow: hidden;
    text-align: center;
    background:  transparent;
    white-space: nowrap;
    position: relative;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  
  /* .verticalphotoloop__logos:before,
  .verticalphotoloop__logos:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100px;
    content: "";
    z-index: 2;
  }
   */
  .verticalphotoloop__logos:before {
    top: 0;
  }
  
  .verticalphotoloop__logos:after {
    bottom: 0;
  }
  
  .verticalphotoloop__logos-slide {
    display: flex; /* Use flex to align vertically */
    flex-direction: column; /* Stack images vertically */
    animation: 25s slideVertical infinite linear;
  }
  
  .verticalphotoloop__logos-slide img {
    max-height: 500px;
  }
  
  .verticalphotoloop__logos-slide p {
    color: var(--color-orange);
    font-weight: 800;
    font-family: var(--font-poppins);
    font-size: 1.5vw;
    padding: 2vw;
  }