.infobox__box {
    width: 312px;
    height: 157px;
    border-radius: 8px;
    border: 1px solid var(--color-orange);
    background: var(--theme-colors-white, #FFF);
    /* smooth-shadow-level-2 */
    box-shadow: 0px 1px 2px 0px rgba(21, 30, 40, 0.08), 0px 2px 4px 0px rgba(13, 23, 33, 0.08);
}

.infobox__text-container {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-left: 2rem;
    gap: 1rem;
}

.infobox__text-title {
    color: black;
    font-family: var(--font-manrope);
    font-size: 1rem;
    font-weight: 600;
}

.infobox__text-name {
    color: black;
    font-family: var(--font-manrope);
    font-size: 2.625rem;
    font-weight: 600;
}