.footer__entire-container {
    display: flex;
    flex-direction: column;
    /* margin-top: 2rem; */
    /* margin-bottom: 2rem; */
    /* margin-left: 10rem; */
    /* margin-right: 10rem; */
    /* padding-top: 2rem; */
    /* padding-bottom: 2rem; */
    padding-top: 2vh;
    padding-left: 8vw;
    padding-bottom: 2vh;
    width: 100%;
    background-color: #26153a;
    font-family: var(--font-poppins);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.footer__columns-container {
    display: flex;
    flex-direction: wrap;
    gap: 15rem;
    row-gap: 2rem;
    flex-wrap: wrap;
}

.footer__column1, .footer__column2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer__column-heading {
    color: var(--color-orange);
    /* font-family: var(--font-manrope); */
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
}

.footer__small-ending-text {
    padding-top: 2rem;
    text-align: left;
}

.footer__small-ending-text p {
    color: #9fa1a7;
    /* font-family: var(--font-manrope); */
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.3px;
}