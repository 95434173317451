.profiledisplay__entireContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    gap: 5%;
    font-family: "Poppins", sans-serif;
}

.profiledisplay__leftSideContainer {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: radial-gradient(101.47% 138.81% at 60.30% 39.26%, rgba(255, 184, 0, 0.80) 0%, rgba(239, 161, 0, 0.00) 100%);
    padding-top: 2%;
    padding-left: 2%;
    padding-bottom: 2%;
    flex: .35;
    justify-content: space-between;
}

.profiledisplay__leftTopAreaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profiledisplay__leftTopAreaInformationContainer {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}
.profiledisplay__image {
    max-width: 60%;
    border-radius: 15px;
}
.profiledisplay__leftTopAreaInformationContainer p {
    color: #000;
    font-size: clamp(3px, 3vw, 45px);
    font-weight: 700;
    max-width: 100%;
    word-wrap: break-word;
}
.profiledisplay__leftTopAreaSpecificsContainer {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.profiledisplay__leftTopAreaSpecificsContainer p {
    color: #000;
    font-size: clamp(3px, 2.5vw, 35px);
    font-weight: 700;
}

.profiledisplay__leftTopAreaUniversityContainer {
    flex-shrink: 0;
}

.profiledisplay__leftTopAreaUniversityContainer p {
    writing-mode: vertical-rl;
    display: inline;
    color: white;
    font-size: clamp(10px, 4vw, 50px);
    font-weight: 700;
    transform: rotate(180deg);
}

.profiledisplay__bar {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 80%;
    height: 3px;
    background: #FFF;
    border-radius: 15px;
}

.profiledisplay__leftBottomAreaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.profiledisplay__leftBottomAreaContainer a {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.profiledisplay__linkedin {
    width: clamp(10px, 6vw, 140px);
    height: auto;
}

.profiledisplay__leftBottomTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    text-align: right;
}

.profiledisplay__leftBottomTextContainer p {
    color: #000;
    font-size: clamp(5px, 1vw, 25px);
    font-weight: 700;
    /* padding-right: 2vw; */
    padding-right: 10px;
}

.profiledisplay__rightSideContainer {
    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: space-between;
    flex: .65;
}

.profiledisplay__rightSideTopPartContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profiledisplay__rightSideContainer h1 {
    font-size: clamp(10px, 3vw, 55px);
    font-weight: 700;
}

.profiledisplay__rightSideInformation {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: visible;
}

.profiledisplay__rightSideInformation h1 {
    font-size: clamp(7px, 2vw, 40px);
}

.profiledisplay__rightSideInformation p {
    font-size: clamp(6px, 1vw, 30px);
    font-weight: 700;
    color: var(--color-orange);
    text-wrap: balance;
}

.profiledisplay__question {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.profiledisplay__question h1 {
    font-size: clamp(7px, 2vw, 40px);
}

.profiledisplay__buttonConfirmationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    margin-bottom: .75rem;
}

.profiledisplay__lastButton {
    display: flex;
    width: clamp(80px, 25vw, 200px);
    height: clamp(2.5px, 5vw, 56px);
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: var(--color-orange);
    backdrop-filter: blur(4px);
    outline: none;
    cursor: pointer;
    color: #000;
    font-family: var(--font-poppins);
    /* font-size: 1rem; */
    font-size: clamp(5.4px, 1.5vw, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: 0.3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-width: 0px;
}

.profiledisplay__redirect-link {
    text-decoration: none;
}

/* everything below is to overwrite scss from bootstrap */
h1 {
    margin-bottom: 0 !important;
}
body {
    line-height: unset !important;
}