.guidedone__entire-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    font-family: var(--font-poppins);
    background-color: var(--color-dark-purple);
}

.guidedone__top-area-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: 50px; */
    flex: .75;
}

.guidedone__top-area-text-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: clamp(20px, 3vw, 40px) !important;
    color: var(--color-orange);
}
.guidedone__top-area-text-container h1special {
    font-size: clamp(20px, 9vw, 120px) !important;
    font-weight: 700 !important;
}

.guidedone__top-area-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: clamp(20px, 5vw, 70px);
}

/* .guidedone__privacy-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}
.guidedone__privacy-container p {
    display: flex;
    font-size: clamp(10px, 2vw, 20px);
    color: var(--color-orange);
    text-align: center;
    margin: 0px;
    padding: 0px;
    font-weight: 700;
} */
/* .guidedone__privacy-container img {
    object-fit: scale-down;
} */

/* .guidedone__privacy-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
} */
/* .guidedone__privacy-container p {
    text-align: center;
} */
/* .guidedone__privacy-container img {
    width: 400px !important;
    height: 400px !important;
} */

.guidedone__bottom-area-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex: .25;
}
.guidedone__bottom-area-container img {
    height: clamp(100px, 15vw, 230px);
    width: clamp(100px, 15vw, 230px);
}

.guidedone__buttons-button {
    cursor: pointer;
	color: #e909a2;
	transition: all 0.3s;
	position: relative;
}
.guidedone__buttons-button span {
	transition: all 0.3s;
    font-weight: 700;
    font-size: clamp(10px, 2vw, 20px);
}
.guidedone__buttons-button::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-color: var(--color-orange);
	border-bottom-color: var(--color-orange);
	transform: scale(0.1, 1);
}
.guidedone__buttons-button:hover span {
	letter-spacing: 2px;
}
.guidedone__buttons-button:hover::before {
	opacity: 1;	
	transform: scale(1, 1);	
}
.guidedone__buttons-button::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.3s;
	/* background-color: rgba(255, 255, 255, 0.1); */
}
.guidedone__buttons-button:hover::after {
	opacity: 0;	
	transform: scale(0.1, 1);
}

/* @media (min-width: 1px) {
.h1, h1 {
    font-size: clamp(20px, 9vw, 120px) !important;
    font-weight: 700 !important;
}
} */