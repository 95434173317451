.colleges__entire-container {
    /* margin: 1rem 6rem; */
}

.colleges__text {
    margin-bottom: 3.5rem;
}

.colleges__text p {
    /* color: #333; */
    color: white;
    font-family: var(--font-manrope);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
}

.colleges__logos-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 10rem;
    justify-content: center;
    padding: 0 15rem;
    align-items: center;
}

.stanford_logo_img {
    width: 4rem;
    height: 100%;
}

.cal_logo_img {
    width: 7rem;
    height: 100%;
}