/* .tokenperson__entire-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-right: 10vw;
    font-family: var(--font-poppins);
}

.tokenperson__photo-container {
    display: flex;
    flex: .3;
}

.tokenperson__text-container {
    display: flex;
    font-weight: 700;
    flex: .7;
    flex-wrap: wrap;
    text-align: center;
} */

.tokenperson__entire-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-poppins);
    background-color: #26153a;
    /* background-color: rgb(215, 215, 215); */
    padding: 2vh 5vw;
    gap: 4vh;
}
.tokenperson__entire-container h1 {
    color: var(--color-orange);
    /* font-size: 3vw; */
    font-size: clamp(15px, 3vw, 60px);
    text-align: center;
}

.tokenperson__quotes-container {
    display: flex;
    /* flex-direction: row; */
    /* align-content: flex-start; */
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 30px;
    justify-content: center;
    text-align: center;
}
.tokenperson__quotes-container p {
    /* width: 100%; */
    /* color: #adadad; */
    color: #d5d5d5;
    font-weight: lighter;
    /* white-space: nowrap; */
    margin: 0;
    font-size: clamp(12px, 1vw, 20px);
    word-wrap: break-word;
    /* width: clamp(300px, 800px); */


    /* width: 100%;
    margin: 0; */
    /* white-space: pre-wrap; */
    /* overflow: hidden; */
    /* display: flex;
    flex-wrap: nowrap;
    color: #585858;
    font-weight: 700; */
}
.tokenperson__quotes-container p:hover {
    color: var(--color-orange);
}

.tokenperson__separation-bar {
    width: 15vw;
    height: 3px;
    border-radius: 40px;
    background-color: white;
}