* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-white);
}

/* anchor text */
a {
    color: unset;
    text-decoration: none;
}

.section__margin {
    padding: 4rem 6rem;
}

.Landing_Page {
    background: white;
    /* max-width: 1890px; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}