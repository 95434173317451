.newwhyus__total-entire-container {
    display: flex;
    justify-content: center;
    background-color: rgb(223, 237, 236);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.newwhyus__entire-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 2560px;
    font-family: var(--font-poppins);
    background-color: inherit;
    padding-top: 3.5vh;
    gap: 3.5vh;
}

.newwhyus__header-text {
    display: flex;
    justify-content: center;
    color: black;
    padding: 0 1vw;
    text-align: center;
}
.newwhyus__header-text p {
    font-weight: 700;
    font-size: clamp(12px, 2.5vw, 65px);
}

.newwhyus__problems-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2.5vw;
    flex-wrap: wrap;
    padding: 0vh 2vw 2vh 2vw;
}

.newwhyus__problems-container > * {
    flex-basis: calc(25% - 2.5vw); /* 25% minus the gap */
    max-width: calc(25% - 2.5vw); /* To prevent items from growing too wide */
    box-sizing: border-box;
}

.newwhyus__box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 15vw;
    height: 12vw;
    min-width: 140px;
    min-height: 112px;
    background-color: #26153a;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 2vw;
    padding-left: 2vw;
    perspective: 1000px;
}
.newwhyus__box p {
    /* color: var(--color-orange); */
    color: white;
    font-weight: 700;
    /* font-size: 1.5vw; */
    font-size: clamp(15px, 1.5vw, 40px)
}

.newwhyus__hover-text {
    display: none;
}

.newwhyus__box:hover {
    background-color: var(--color-dark-purple);
}

.newwhyus__box:hover .newwhyus__box-text {
    display: none;
}

.newwhyus__box:hover .newwhyus__hover-text {
    display: flex;
    color: var(--color-orange);
    /* font-size: 1vw; */
    font-size: clamp(10px, 1.2vw, 35px);
}

@media screen and (min-width: 320px) and (max-width: 360px) {
    .newwhyus__problems-container {
        column-gap: 8vw;
    }
}

@media screen and (min-width: 360px) and (max-width: 460px) {
    .newwhyus__problems-container {
        column-gap: 15vw;
    }
}

@media screen and (min-width: 360px) and (max-width: 460px) {
    .newwhyus__problems-container {
        column-gap: 10vw;
    }
    .newwhyus__box {
        min-width: 150px;
    }
}