.squarebox__entire-container {
    display: flex;
    border: 5px dashed grey;
    /* width: 35vmin; */
    /* height: 35vmin; */
    width: clamp(230px, 35vw, 360px);
    height: clamp(230px, 35vw, 360px);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.squarebox__entire-container h1 {
    font-family: var(--font-poppins);
    /* font-size: 120px; */
    color: grey;
}