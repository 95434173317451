.newheader__entire-container {
    display: flex;
    flex-direction: column;
    /* padding: 1vh 9vw; */
    padding: 2rem 10rem;
}

.newheader__top-container {
    display: flex;
    flex-direction: column;
}

.newheader__text-container {
    font-family: var(--font-poppins);
    height: 100%;
    width: 70vw;
}
.newheader__text-container h1 {
    color: black;
    font-size: 5vw;
}
.newheader__text-container span {
    /* color: #0feb3f; */
    color: var(--color-orange);
    font-size: 5vw;
    /* font-family: var(--font-satisfy); */
}

.newheader__subtext-container {
    display: flex;
    /* padding-top: 32vh; */
}
.newheader__subtext-container p {
    font-family: var(--font-poppins);
    font-size: 2vw;
}

.newheader__onboard-button-container {
    display: flex;
    font-family: var(--font-poppins);
    padding-top: 2vh;
}
.newheader__onboard-button-container button {
    width: 12vw;
    height: 7.5vh;
    border: none;
    background: var(--color-orange);
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.5vw;
    font-weight: 700;
}

.newheader__bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.newheader__image-container {
    display: flex;
}
/* .newheader__image-container img {
    width: 100%;
    height: auto;
} */

.newheader__image-explanation-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.newheader__actual-explanation-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    width: 12vw;
}
.newheader__actual-explanation-container img {
    width: 5vw;
}
.newheader__actual-explanation-container p {
    text-align: center;
    font-family: var(--font-manrope);
}